.corporate_services_section {
  padding: 50px 0;
}
.cservices_list {
  display: flex;
  flex-wrap: wrap;
}
.cservices_list li {
  width: 50%;
  float: left;
  padding-bottom: 7.5px;
  position: relative;
  padding-left: 25px;
}
.cservices_list li:before {
  position: absolute;
  left: 0;
  top: 3px;
  height: 18px;
  width: 18px;
  background-image: url("../../../public/images/right-chevron.png");
  font-weight: 900;
  font-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}
.corporate_services_section .container {
  max-width: 75%;
}
