
.add_asset_div h3{
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}
.property_type .select_container{
    width: 33.33% !important;
}
.input_instruction{
    font-size: 13px;
}
.admin_page .card{
    padding: 25px;
}
.admin_page .nav-tabs .nav-item{
    width: 33.33%;
    border-bottom: 2px solid rgba(0,0,0,0.1);
}
.admin_page .nav-tabs .nav-item button{
    width: 100%;
    background-color: #ffffff;
    border: none;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 16px 32px;
    color: #42526E;
    position: relative;
    font-weight: 600;
}
.admin_page .nav-tabs .nav-item button.active{
    color: #000000;
    background-color: #f5f5f5;
}
.admin_page .nav-tabs .nav-item button:after{
    content: "";
    background: #fce15a;
    height: 3px;
    position: absolute;
    padding: 0;
    bottom: -3px;
    left: 0;
    display: block;
    margin: 0;
    white-space: normal;
    transition: left .3s ease-in-out;
}
.admin_page .nav-tabs .nav-item button.active:after{
    right: 0;
}