/*******************for mobile view***************/

@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .home_services {
    flex-wrap: wrap;
  }
  .col-md-custom-5 {
    width: 100%;
    max-width: 100%;
  }
  .location_input {
    width: 100% !important;
  }
  .service_input {
    width: 100% !important;
  }
  .banner_info {
    width: 100% !important;
  }
  .header .navbar-nav .nav-link {
    padding: 7.5px !important;
  }
  .banner_info h1 {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  .login_signup a:not(:last-child):after {
    top: 7.5px !important;
  }
  .banner_content {
    width: 90% !important;
  }
  .recent_searches li:first-child {
    display: none;
  }
  .recent_searches li {
    font-size: 12px !important;
  }
  .recent_searches li a {
    font-size: 12px !important;
  }
  .container {
    max-width: 90% !important;
  }
  .col-md-custom-7 {
    width: 100%;
    max-width: 100%;
  }
  .home_services {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 90%;
    margin: 0 auto;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .services_container.col-md-6:nth-child(2) {
    padding-top: 50px;
  }
  .movers_services .services_container:nth-child(2) {
    padding-top: 25px !important;
  }

  /******************modals*****************/
  .selection_modal .modal-header {
    font-size: 20px !important;
    padding-left: 15px !important;
  }
  .property_selection_form .form-group .select_container {
    width: 100% !important;
  }
  .service_details_s .container {
    padding-left: 0 !important;
  }
  .detailedModal .modal-body {
    padding: 15px !important;
  }
  .service_details_s .col-md-3,
  .service_details_s .col-md-5 {
    padding: 0;
  }
  .service_tab_scroll {
    padding: 15px 15px 15px 0 !important;
  }
  .service_details_s .one_service_btn {
    flex-wrap: wrap;
  }
  .service_details_s .one_service_btn button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
  .side_modal .modal-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .pest_control_modal .modal-body {
    padding: 15px;
  }
  .send_by {
    width: 100%;
    margin: 5px 0;
  }
  .service_details_s .col-md-4 {
    padding-right: 0;
    margin-top: 15px;
  }
  .selection_modal .modal-body {
    padding: 15px;
  }
  .shifting_form .row .form-group {
    padding: 0 !important;
    width: 100% !important;
  }
  .property_selection_form .form-group .check_container {
    width: 100% !important;
  }
  .main_heading h2 {
    font-size: 28px !important;
  }
  .selection_modal .modal-header {
    font-size: 16px !important;
    line-height: 18px;
  }
  .banner_section {
    background-size: cover !important;
  }
  .CorporateServices.banner_section {
    height: 65vh;
  }
  .CorporateServices.banner_section .banner_info h1 {
    margin-bottom: 0;
  }
  .cservices_list li {
    width: 100% !important;
  }
  .mobile_services {
    display: block !important;
    padding: 0px 15px !important;
  }
  div#serviceRentalServices {
    padding-top: 30px;
  }
  .rsc-float-button {
    bottom: 15px !important;
    right: 15px !important;
  }
}

/*******************for Tablets or iPad view***************/

@media screen and (min-device-width: 481px) and (max-device-width: 600px) {
  .home_services {
    flex-wrap: wrap;
  }
  .footer_section .col-md-custom-5 {
    width: 50%;
    max-width: 50%;
  }
  .location_input {
    width: 100% !important;
  }
  .service_input {
    width: 100% !important;
  }
  .banner_info {
    width: 100% !important;
  }
  .header .navbar-nav .nav-link {
    padding: 7.5px !important;
  }
  .banner_info h1 {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  .login_signup a:not(:last-child):after {
    top: 7.5px !important;
  }
  .banner_content {
    width: 90% !important;
  }
  .recent_searches li:first-child {
    display: none;
  }
  .recent_searches li {
    font-size: 12px !important;
  }
  .recent_searches li a {
    font-size: 12px !important;
  }
  .container {
    max-width: 90% !important;
  }
  .col-md-custom-7 {
    width: 100%;
    max-width: 100%;
  }
  .home_services {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    margin: 0 auto;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .services_container.col-md-6:nth-child(2) {
    padding-top: 50px;
  }
  .movers_services .services_container:nth-child(2) {
    padding-top: 25px !important;
  }
  .services_section .col-md-2 {
    width: 50% !important;
  }
  .col-md-custom-7 {
    width: 50%;
  }
  .services_container {
    width: 90%;
    margin: 0 auto;
  }
  .services_container .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .services_container .col-md-4,
  .services_container .col-md-3,
  .services_container .col-md-6 {
    width: 50%;
  }
  .home_services .col-md-3 {
    width: 50%;
  }

  /******************modals*****************/
  .selection_modal .modal-header {
    font-size: 20px !important;
    padding-left: 15px !important;
  }
  .property_selection_form .form-group .select_container {
    width: 100% !important;
  }
  .service_details_s .container {
    padding-left: 0 !important;
  }
  .detailedModal .modal-body {
    padding: 15px !important;
  }
  .service_details_s .col-md-3,
  .service_details_s .col-md-5 {
    padding: 0;
    width: 100% !important;
  }
  .service_tab_scroll {
    padding: 15px 15px 15px 0 !important;
  }
  .service_details_s .one_service_btn {
    flex-wrap: wrap;
  }
  .service_details_s .one_service_btn button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
  .side_modal .modal-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .pest_control_modal .modal-body {
    padding: 15px;
  }
  .send_by {
    width: 100%;
    margin: 5px 0;
  }
  .service_details_s .col-md-4 {
    padding-right: 0;
    margin-top: 15px;
  }
  .selection_modal .modal-body {
    padding: 15px;
  }
  .shifting_form .row .form-group {
    padding: 0 !important;
    width: 100% !important;
  }
  .property_selection_form .form-group .check_container {
    width: 100% !important;
  }
  .main_heading h2 {
    font-size: 28px !important;
  }
  .selection_modal .modal-header {
    font-size: 16px !important;
    line-height: 18px;
  }
  .banner_section {
    background-size: cover !important;
  }
  .CorporateServices.banner_section {
    height: 65vh;
  }
  .CorporateServices.banner_section .banner_info h1 {
    margin-bottom: 0;
  }
  .cservices_list li {
    width: 100% !important;
  }
  .col-md-custom-5 {
    width: 50%;
    max-width: 50%;
  }
  .mobile_services {
    display: block !important;
    padding: 30px 0px !important;
  }
  .footer_section .col-md-3 {
    width: 50%;
  }
}

/*******************for Tablets or iPad view***************/

@media screen and (min-device-width: 601px) and (max-device-width: 767px) {
  .home_services {
    flex-wrap: wrap;
  }
  .footer_section .col-md-custom-5 {
    width: 50%;
    max-width: 50%;
  }
  .banner_info {
    width: 100% !important;
  }
  .header .navbar-nav .nav-link {
    padding: 7.5px !important;
  }
  .banner_info h1 {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  .login_signup a:not(:last-child):after {
    top: 7.5px !important;
  }
  .banner_content {
    width: 90% !important;
  }
  .recent_searches li:first-child {
    display: none;
  }
  .recent_searches li {
    font-size: 12px !important;
  }
  .recent_searches li a {
    font-size: 12px !important;
  }
  .container {
    max-width: 90% !important;
  }
  .col-md-custom-7 {
    width: 33.33%;
    max-width: 33.33%;
  }
  .home_services {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    margin: 0 auto;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .services_container.col-md-6:nth-child(2) {
    padding-top: 50px;
  }
  .movers_services .services_container:nth-child(2) {
    padding-top: 25px !important;
  }
  .services_section .col-md-2 {
    width: 33.33% !important;
  }
  .col-md-custom-7 {
    width: 50%;
  }
  .services_container {
    width: 90%;
    margin: 0 auto;
  }
  .services_container .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .services_container .col-md-4,
  .services_container .col-md-3 {
    width: 33.33%;
  }
  .home_services .col-md-3 {
    width: 33.33%;
  }
  .movers_services .col-md-6 {
    width: 50%;
  }

  /******************modals*****************/
  .selection_modal .modal-header {
    font-size: 20px !important;
    padding-left: 15px !important;
  }
  .property_selection_form .form-group .select_container {
    width: 100% !important;
  }
  .service_details_s .container {
    padding-left: 0 !important;
  }
  .detailedModal .modal-body {
    padding: 15px !important;
  }
  .service_details_s .col-md-3 {
    padding: 0;
    width: 100% !important;
  }
  .service_details_s .col-md-5,
  .service_details_s .col-md-4 {
    width: 50%;
  }
  .service_tab_scroll {
    padding: 15px 15px 15px 0 !important;
  }
  .service_details_s .one_service_btn {
    flex-wrap: wrap;
  }
  .service_details_s .one_service_btn button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
  .side_modal .modal-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .pest_control_modal .modal-body {
    padding: 15px;
  }
  .send_by {
    width: 48%;
    margin: 5px;
  }
  .service_details_s .col-md-4 {
    padding-right: 0;
    margin-top: 15px;
  }
  .selection_modal .modal-body {
    padding: 15px;
  }
  .shifting_form .row .form-group {
    padding: 0 !important;
    width: 100% !important;
  }
  .property_selection_form .form-group .check_container {
    width: 100% !important;
  }
  .main_heading h2 {
    font-size: 28px !important;
  }
  .banner_section {
    background-size: cover !important;
  }
  .CorporateServices.banner_section {
    height: 65vh;
  }
  .CorporateServices.banner_section .banner_info h1 {
    margin-bottom: 0;
  }
  .cservices_list li {
    width: 100% !important;
  }
  .property_selection_form .row .col-md-4 {
    width: 50%;
  }
  .col-md-custom-5 {
    width: 33.33%;
    max-width: 33.33%;
  }
  .mobile_services {
    display: block !important;
    padding: 30px 0 !important;
  }
  div#serviceRentalServices {
    padding-top: 30px;
  }
  .footer_section .col-md-3 {
    width: 50%;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .home_services {
    flex-wrap: wrap;
  }
  .footer_section .col-md-custom-5 {
    width: 50%;
    max-width: 50%;
  }
  .banner_info {
    width: 100% !important;
  }
  .header .navbar-nav .nav-link {
    padding: 7.5px !important;
  }
  .banner_info h1 {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  .login_signup a:not(:last-child):after {
    top: 7.5px !important;
  }
  .banner_content {
    width: 90% !important;
  }
  .recent_searches li:first-child {
    display: none;
  }
  .recent_searches li {
    font-size: 12px !important;
  }
  .recent_searches li a {
    font-size: 12px !important;
  }
  .container {
    max-width: 90% !important;
  }
  .col-md-custom-7 {
    width: 33.33%;
    max-width: 33.33%;
  }
  .home_services {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    margin: 0 auto;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .services_container.col-md-6:nth-child(2) {
    padding-top: 50px;
  }
  .movers_services .services_container:nth-child(2) {
    padding-top: 25px !important;
  }
  .services_section .col-md-2 {
    width: 33.33% !important;
  }
  .col-md-custom-7 {
    width: 50%;
  }
  .services_container {
    width: 90%;
    margin: 0 auto;
    max-width: 90%;
    flex: 90%;
  }
  .services_container .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .services_container .col-md-4,
  .services_container .col-md-3 {
    width: 33.33%;
  }
  .home_services .col-md-3 {
    width: 33.33%;
  }
  .movers_services .col-md-6 {
    width: 50%;
  }

  /******************modals*****************/
  .selection_modal .modal-header {
    font-size: 20px !important;
    padding-left: 15px !important;
  }
  .property_selection_form .form-group .select_container {
    width: 100% !important;
  }
  .service_details_s .container {
    padding-left: 0 !important;
  }
  .detailedModal .modal-body {
    padding: 15px !important;
  }
  .service_details_s .col-md-3 {
    padding: 0;
    width: 100% !important;
  }
  .service_details_s .col-md-5,
  .service_details_s .col-md-4 {
    width: 50%;
  }
  .service_tab_scroll {
    padding: 15px 15px 15px 0 !important;
  }
  .service_details_s .one_service_btn {
    flex-wrap: wrap;
  }
  .service_details_s .one_service_btn button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
  .side_modal .modal-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .pest_control_modal .modal-body {
    padding: 15px;
  }
  .send_by {
    width: 48%;
    margin: 5px;
  }
  .service_details_s .col-md-4 {
    padding-right: 0;
    margin-top: 15px;
  }
  .selection_modal .modal-body {
    padding: 15px;
  }
  .shifting_form .row .form-group {
    padding: 0 !important;
    width: 100% !important;
  }
  .property_selection_form .form-group .check_container {
    width: 100% !important;
  }
  .main_heading h2 {
    font-size: 28px !important;
  }
  .banner_section {
    background-size: cover !important;
  }
  .CorporateServices.banner_section {
    height: 65vh;
  }
  .CorporateServices.banner_section .banner_info h1 {
    margin-bottom: 0;
  }
  .cservices_list li {
    width: 100% !important;
  }
  .property_selection_form .row .col-md-4 {
    width: 50%;
  }
  .col-md-custom-5 {
    width: 33.33%;
    max-width: 33.33%;
  }
  .mobile_services {
    display: block !important;
    padding: 30px 0 !important;
  }
  div#serviceRentalServices {
    padding-top: 30px;
  }
  .footer_section .col-md-3 {
    width: 50%;
  }
}
