.property_all_images{
    display: flex;
}
.property_single_img{
    position: relative;
    height: 100%;
}
.property_all_images .property_single_img:nth-child(1){
    width: 60%;
    float: left;
    flex: 60%;
}
.property_all_images .property_single_img:nth-child(2){
    width: 40%;
    float: left;
    flex: 40%;
}
.property_all_images .property_single_img img{
    padding: 5px;
    width: 100%;
    height: 100%;
}
.image_with_more_link{
    position: relative;
}

.property_all_images {
    display: -ms-flexbox; /* IE 10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE 10 */
    flex-wrap: wrap;
    height: 100%;
  }
  .property_all_images .property_single_img:nth-child(2) img{
    height: 50%;
  }
  .image_with_more_link{
    height: 50%;
  }
  .image_with_more_link img{
    height: 100% !important;
  }
  .image_with_more_link a{
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 4px;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    
  }
  .single_prop_details.property_list_div h3{
    margin-bottom: 5px;
    font-size: 28px;
    margin-bottom: 15px;
  } 
  .prop_address{
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  .prop_address i{
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 14px;
  }
  .prop_full_details{
    max-width: 96%;
}
.prop_address{
    font-size: 13px;
    max-width: 90%;
}
.property_img_card a,.property_img_card a img{
   height: 100%;
}
.prop_full_details h4{
    font-size: 25px!important;
    font-weight: 700!important;
}
.prop_full_details .property_btns button{
  font-size: 18px;
  font-weight: 700;
}
.single_prop_details .card{
    padding: 15px;
}
.property_features h3{
    font-size: 24px;
    margin-bottom: 15px;
}
.features_list{
 display: flex;
 flex-wrap: wrap;
}
.features_list li{
    width: 50%;
}
.features_list .feature_label{
    color: #606060;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 7.5px;
    text-transform: capitalize;
}
.feature_content{
    color: #303030;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
}
.property_img_card{
    height: 100%;
}
.features_heading{
    margin-bottom: 30px !important;
}
.features_heading span{
    border-bottom: 3px solid #fce15a;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.single_feature div{
    width:50%;
    float: left;
    padding: 15px 15px 0;
}
.card.property_features{
  height: 100%;
  max-height: 270px;
}
.amenities_list li{
  position: relative;
  padding-left: 7.5px;
}
.amenities_list li:after{
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  background-image: url("../../../../../public/images//black-circle.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
}
.amenities_list .feature_content{
font-weight: normal;
}