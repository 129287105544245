@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css");

html {
  scroll-behavior: smooth;
}
body {
  font-family: "DM Sans", sans-serif;
  color: #464646;
}
.container {
  max-width: 92%;
  padding: 0;
}
.navbar {
  padding-left: 0;
  padding-right: 0;
}
ul,
li {
  padding: 0;
  list-style-type: none;
}
label {
  text-transform: capitalize;
}
a,
button {
  text-decoration: none;
  transition: all 0.3s ease 0s;
}
p {
  line-height: 24px;
}
a:hover {
  text-decoration: none;
}
.text-colored {
  color: #fce15a;
}
.col-md-custom-7 {
  width: 14.28%;
  max-width: 14.28%;
}
.col-md-custom-8 {
  width: 12.5%;
  max-width: 12.5%;
}
.row {
  margin: 0;
}
input,
select {
  text-transform: capitalize;
}
.mobile_banner img {
  height: 250px;
}
.navbar-toggler-icon {
  height: 20px;
  width: 20px;
}
.modal-open {
  overflow-y: hidden;
  padding-right: 0 !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url("../public/images/close-white.png");
}
.navbar-collapse.collapse.show .navbar-nav {
  padding-bottom: 15px;
}
.modal-open .modal {
  z-index: 9999999;
}
.modal-backdrop.show {
  z-index: 999999;
}
.btn {
  text-transform: capitalize;
}
.modal-backdrop.show {
  display: none;
}
.custom_breadcrumb {
  background-color: transparent;
  padding: 7.5px 0;
  margin-bottom: 25px;
}
li.breadcrumb-item.align-items-center {
  display: flex;
}
.btn-black {
  background-color: rgba(0, 0, 0, 1);
  border-color: rgba(0, 0, 0, 1);
  font-size: 18px;
  color: #ffffff;
  padding: 12px;
  font-weight: 500;
}
.btn-yellow {
  background-color: #fce15a;
  border-color: #fce15a;
  font-size: 18px;
  color: #000000;
  padding: 12px;
  font-weight: 500;
}
.btn-yellow:hover,
.btn-yellow:focus {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
.btn-md {
  padding: 12px 24px;
  font-size: 15px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
.text-muted {
  color: #787676;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}
.btn-sm {
  padding: 7.5px;
  font-size: 12px;
}
.btn-black:hover,
.btn-black:focus {
  background-color: #fce15a;
  color: #000000;
  border-color: #fce15a;
}
img {
  max-width: 100%;
}
.col-md-custom-5 {
  width: 20%;
  max-width: 20%;
}
.modal {
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.25);
}
.form-control {
  padding: 12px 15px;
  height: auto;
  border-radius: 3px;
}
.form-control:focus {
  border: 1px solid #ced4da;
  outline: none;
  box-shadow: none;
}
label.form-label {
  width: 100%;
}
.error_message {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  display: block;
  overflow: hidden;
}
.form-group {
  position: relative;
}
.form-group .form-group {
  margin-bottom: 0;
}
.form-group .form-group .form-label {
  margin: 0;
}
.form-group.form-field.property_status {
  overflow: hidden;
  clear: both;
  display: block;
}
.form-group.form-field.otherServices {
  overflow: hidden;
  clear: both;
  display: block;
}
.built_up {
  clear: both;
  display: block;
}
/*******************footer_section****************/
.footer_section {
  background-color: #000000;
  padding: 30px 0;
}
.footer_section .container {
  max-width: 89%;
}
.single_footer {
  margin-top: 15px;
}
.single_footer ul {
  margin-bottom: 0;
}
.single_footer h4 {
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
}
.footer_links li a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  text-transform: capitalize;
}
.footer_links li a:hover {
  color: #fce15a;
  text-decoration: none;
}
.footer_links li {
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.75);
}
.col-md-custom-5 {
  width: 20%;
  max-width: 20%;
}

.social_links li {
  padding-right: 12px;
}
.social_links li a {
  font-size: 14px;
}
.social_links li a {
  padding-left: 25px;
  position: relative;
}
.social_links li a i {
  position: absolute;
  left: 0;
  top: 3px;
}
.form-group .form-group {
  position: static;
}

/*******************copyright_section*****************/
.copyright_section {
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  background-color: #000000;
}
.copyright_div p {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0;
}

/*****************side_modal********************/
.side_modal {
  padding-right: 0 !important;
}
.side_modal .modal-dialog {
  margin: 0;
  height: 100%;
  float: right;
  min-width: 360px;
  max-width: 360px;
}
.side_modal .modal-content {
  height: 100%;
  border-radius: 0;
  border: none;
}
.side_modal .modal-header {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.side_modal .modal-header button {
  margin: 0;
}
.side_modal .modal-header button:hover,
.side_modal .modal-header button:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.side_modal .modal-header button i {
  font-size: 16px;
}
.service_list li a {
  padding: 15px;
  display: block;
  color: #333;
  text-transform: capitalize;
}
.service_list li a span {
  margin-right: 15px;
}
.service_list li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.service_list li a span img {
  height: 48px;
  width: 48px;
  border-radius: 3px;
}
.service_list li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.side_modal .modal-body {
  padding: 0;
}
.show_modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.selection_modal .modal-dialog {
  width: 90%;
  max-width: 725px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}
.selection_modal .modal-body {
  padding: 30px 50px;
  max-height: 88vh;
  overflow-y: auto;
}
.pest_control_modal .modal-body {
  padding: 30px 30px 45px 30px;
  position: relative;
}
.please_select .form-check {
  padding-left: 0;
}

.selection_modal .modal-header {
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  padding-left: 25px;
}
.selection_modal .modal-header button {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.centered_Modal .modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
}
.please_select h3 {
  font-size: 22px;
  text-align: center;
  margin: 30px 0;
}
.select_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
}
.shifting_form .row .form-group {
  width: 50%;
  float: left;
}
.shifting_form .row .form-group:first-child {
  padding-left: 0;
  padding-right: 7.5px;
}
.shifting_form .row .form-group:nth-child(2) {
  padding-left: 7.5px;
  padding-right: 0;
}
#moving_items .form-group .select_container {
  width: 100%;
}
/* Hide the browser's default radio button */
.select_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.select_container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/* When the radio button is checked, add a blue background */
.select_container input:checked ~ .checkmark {
  background-color: #000000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.select_container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.select_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.select_container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../public/images/tick.png");
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.selecton_form .form-group {
  margin-bottom: 0;
  padding: 15px;
  overflow: hidden;
}
#homeCleaningModal .selecton_form .form-group {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#homeCleaningModal .select_container {
  margin-bottom: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}
#homeCleaningModal .select_container .checkmark {
  top: 6px;
}
.form-group-btn {
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.detailed_modal .modal-dialog {
  width: 100%;
  max-width: 100%;
}
.detailed_modal .modal-header {
  padding: 20px;
}
.detailed_modal .modal-header {
  font-size: 20px;
}
.sservice_tabs_ul .nav {
  width: 100%;
  display: block;
  padding-right: 15px;
}
.detailed_modal {
  z-index: 999999;
}
.sservice_tabs_ul {
  margin-top: 15px;
}
.sservice_tabs_ul .nav li {
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
}
.sservice_tabs_ul .nav li a {
  float: right;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 3px;
  padding: 7px 12px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.75);
}
.service_details_s .row {
  width: 100%;
}
.service_details_s .container {
  max-width: 100%;
  padding-left: 100px;
  padding-right: 0;
}
.sservice_tabs_ul .nav li a.active {
  background-color: #000000;
  color: #ffffff;
}
.service_details_s .col-md-3 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.service_tab_scroll {
  padding: 15px;
  max-height: 88vh;
  overflow-y: auto;
  padding-right: 30px;
}
.single_service_tab_scroll h2 {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.one_service_img {
  width: 100%;
  height: 215px;
  overflow: hidden;
}
.one_service_img img {
  width: 100%;
  min-height: 215px;
}
.one_service_div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.one_service_div:not(:first-child) {
  margin: 25px 0;
}
.one_service_info {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.one_service_info h5 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 900;
}
.service_duration {
  font-size: 12px;
  color: #757575;
}
.service_duration {
  margin-bottom: 0;
}
.service_duration span {
  padding-right: 5px;
}
.service_duration i {
  font-size: 10px;
}
.service_duration img {
  height: 12px;
}
.service_flex {
  justify-content: space-between;
}
.one_service_listing {
  width: 100%;
  padding-top: 10px;
  margin-bottom: 0;
}
.one_service_listing li {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.75);
  position: relative;
  padding-left: 18px;
}
.one_service_listing li:not(:last-child):before {
  content: "\2e";
  position: absolute;
  font-family: "Font Awesome 6 Pro" !important;
  font-weight: 900;
  position: absolute;
  left: 0;
  top: 0;
  color: #000000;
  font-size: 30px;
  line-height: 0px;
}
.one_service_listing li a {
  font-size: 13px;
  color: #000000;
  font-weight: 700;
  text-decoration: underline !important;
}
.one_service_div {
  margin: 15px 0;
}
.one_service_full_info {
  padding: 15px;
  max-height: 88vh;
  overflow-y: auto;
}
.service_details_s .col-md-4 {
  padding-left: 0;
}
.one_serv_full_details {
  margin-top: 15px;
}
.included_serv h4 {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 15px;
}
.included_serv ul {
  position: relative;
  list-style-type: none;
  counter-reset: css-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
}
.included_serv ul:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: auto;
  background-color: #f5f5f5;
  width: 1px;
  z-index: -1;
}
.included_serv ul li {
  counter-increment: css-counter 1; /* Increase the counter by 1. */
  position: relative;
  padding-left: 30px;
}

.included_serv ul li:before {
  content: counter(css-counter);
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #cccccc;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 600;
}
.single_included_serv h6 {
  font-weight: 600;
}
.one_service_full_info p {
  font-size: 14px;
}

.check_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check_container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 1);
}
/* Create the checkmark/indicator (hidden when not checked) */
.check_container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_container input:checked ~ .checkmark:after {
  display: block;
}
.check_container input:checked ~ .checkmark {
  background-color: #000000;
}

/* Style the checkmark/indicator */
.check_container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fce15a;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form_label {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  display: block;
  text-transform: capitalize;
}
.apartment_type {
  overflow: hidden;
  clear: both;
}
.apartment_type .check_container {
  width: 33.33%;
  float: left;
}
.property_selection_form .form-group .check_container {
  width: 50%;
  float: left;
}
.property_selection_form .form-group .select_container {
  width: 50%;
  float: left;
}
.gorm-group__btn {
  text-align: center;
  margin-bottom: 15px;
  overflow: hidden;
  clear: both;
  display: block;
}
.loan_form .form-group {
  width: 50%;
  float: left;
  position: relative;
  padding: 15px 15px 20px 15px;
}
.gender_flex {
  display: flex;
  margin-top: 10px;
}
.gender_flex .form-check {
  padding-right: 15px;
}
.loan_form .form-label {
  font-weight: 600;
  margin-bottom: 10px;
}
.label-md {
  font-size: 13px;
}
.loan_form .row {
  margin: 0px -15px;
}
.form-group__btn {
  text-align: center;
  margin-top: 15px;
}
.shifting_items ul {
  border-bottom: none;
}
.shifting_items li {
  margin-right: 7.5px;
}
.shifting_items li a {
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 0 !important;
  margin-bottom: -1px !important;
  font-weight: 600;
}
.shifting_items li a.active {
  background-color: rgba(0, 0, 0, 1) !important;
  color: #ffffff !important;
}
.shifting_items {
  margin-top: 30px;
}
.shifting_items .tab-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.shifting_items_info {
  padding: 15px 15px 15px 35px;
  overflow: hidden;
}
.shifting_items_info ul {
  margin-bottom: 0;
}
.shifting_items_info ul li {
  width: 33.33%;
  float: left;
  font-size: 13px;
  padding-left: 15px;
  position: relative;
  margin: 0;
  padding: 5px 5px 5px 20px;
  text-transform: capitalize;
}
.shifting_items_info ul li:after {
  content: "\2e";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  position: absolute;
  left: 0;
  font-size: 35px;
  color: #fce15a;
  line-height: 0;
  top: 6px;
}
.shifting_items h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 7px;
}
.shifting_items_info h6 {
  font-size: 15px;
  font-weight: 800;
}
.modal.show {
  display: block;
}
.MuiSlider-colorPrimary {
  color: #000000 !important;
}
.MuiSlider-valueLabel {
  background-color: #fce15a !important;
  color: #ffffff !important;
}
.price_slide {
  position: relative;
  padding-bottom: 0px;
  width: 97%;
  margin: 0 auto;
}
.price_slide .form-group.form-field {
  position: absolute;
  border: none;
  top: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  background-color: transparent;
}
.price_slide .form-group.form-field input {
  background-color: transparent;
  padding: 0;
  border: none;
  width: fit-content;
}
.form-group.form-field.price_max {
  right: -12px;
  bottom: -12px;
  top: auto;
}
.form-group.form-field.price_max input {
  text-align: right;
}
.form-group.form-field.price_min {
  left: -4px;
  bottom: -12px;
  top: auto;
}
.moving_items {
  padding: 0 15px;
}
.moving_items .form-group {
  padding-left: 0;
  padding: 5px 0;
}
.moving_items .select_container {
  font-size: 13px;
}
.moving_items .form-group {
  width: 100%;
}
.service_details_s .container {
  width: 100%;
  max-width: 100% !important;
}
.detailedModal .modal-body {
  padding: 30px;
}
.carpenter_list li {
  position: relative;
  padding-left: 25px;
  font-size: 16px;
  line-height: 28px;
  margin-top: 10px;
}
.carpenter_list li:after {
  content: "\2e";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  position: absolute;
  left: 0;
  font-size: 40px;
  color: #000000;
  line-height: 0;
  top: 4px;
}
.carpenter_list li h4 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 24px;
}
.carpenter_list li p {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 24px;
}
.login_signup {
  display: flex;
}
.login_signup a {
  padding: 0 5px !important;
  position: relative;
}
.login_signup a:not(:last-child):after {
  position: absolute;
  right: -3px;
  top: 0;
  content: "/";
}
.please_select .col-md-4 .form-group {
  padding: 0;
}
.form-group.form-field.pest_service .select_container {
  margin-bottom: 7.5px;
}
.form-group.form-field.gender .select_container {
  width: auto;
  margin-right: 15px;
  margin-top: 10px;
}
.one_service_full_info {
  display: none;
}
.one_service_full_info.show {
  display: block;
}
.form-group.form-field.priceRangeCheck {
  overflow: hidden;
  clear: both;
  display: block;
}
.form-group.form-field.propertyType {
  overflow: hidden;
  clear: both;
  display: block;
}
.form-group.form-field.rentalType {
  overflow: hidden;
  clear: both;
  display: block;
}
.movers_services .services_container {
  padding: 0px 5px;
}
.form-group.form-field.pest_service {
  overflow: hidden;
  clear: both;
  display: block;
}
.send_by {
  display: flex;
  align-items: center;
  margin: 0px 7.5px;
}
.send_by img {
  height: 30px;
  padding-right: 7.5px;
}
.form_send_options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.one_service_btn {
  margin-top: 7.5px;
  justify-content: space-between;
  display: flex;
}
.one_service_btn button {
  width: 49%;
}
.one_service_btn button:first-child {
  margin-right: 5px;
}
.one_service_btn button:nth-child(2) {
  margin-left: 5px;
}
.one_service_btn button img {
  height: 18px;
  width: 18px;
  margin-right: 7.5px;
}
section.home_services.movers_services .col-md-4,
section.home_services.movers_services .col-md-3 {
  padding: 0px 5px;
}

.basic_charge span {
  position: relative;
  font-size: 13px;
  padding-left: 15px;
  font-weight: 500;
  text-transform: capitalize;
  display: none;
}
.basic_charge span:before {
  position: absolute;
  left: 0;
  top: -1px;
  height: 8px;
  width: 8px;
  background-image: url("../public/images/star.png");
  font-weight: 900;
  font-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}
input[type="date"],
input[type="email"] {
  text-transform: lowercase !important;
}
.serviceDate {
  overflow: hidden;
  clear: both;
}
ul.copy_links {
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}
.copy_links li {
  color: #ffffff;
  font-size: 14px;
}
.copy_links li {
  padding: 0px 7px;
}
.copyright_section .container {
  max-width: 89%;
}
.admin_page {
  padding-top: 70px;
  padding-bottom: 30px;
}
ol.breadcrumb.custom_breadcrumb .breadcrumb-item .breadcrumb-link {
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 10px;
  padding: 0;
  text-transform: capitalize;
}
ol.breadcrumb.custom_breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-size: 10px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #000000 !important;
}
.mobilesforsale .container {
  max-width: 78% !important;
}
.mobilesforsale {
  padding-bottom: 0 !important;
}
.property_btns button {
  font-size: 14px;
  padding: 12px 28px;
}
.property_btns a {
  font-size: 14px;
}
.property_list_div h3 {
  color: #303030;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
}
.prodct_images img {
  height: 150px !important;
  width: auto !important;
}
.mobileslist .property_info h6 {
  font-size: 16px;
}
.prodct_images {
  width: auto !important;
  padding-right: 30px;
}
.direct_cost {
  font-size: 12px;
  color: #388e3c;
}
.textarea-main {
  width: 100% !important;
}
.textarea-main textarea {
  min-height: 120px;
}
.direct_cost span {
  text-decoration: line-through;
  font-size: 12px;
  color: #878787;
  padding-right: 15px;
}
.mobileslist .property_cost h4 {
  margin-bottom: 5px !important;
}
.direct_cost {
  margin-bottom: 5px;
}
.rental_packers .home_services {
  padding-top: 0;
}
.rental_packers .home_services .container {
  max-width: 90% !important;
}
.rental_packers > .container {
  max-width: 75%;
}
.rental_packers .home_services .col-md-custom-7 {
  width: 50%;
  max-width: 50%;
}
input[type="file"]{
  padding-top: 9px;
  padding-bottom: 9px;
}
