.header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
}
.main-navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.main-navbar .navbar-logo {
  color: #ffffff;
}
.main-navbar .navbar-logo {
  font-size: 36px;
  font-weight: 900;
}
.header .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 15px;
  text-transform: capitalize;
}
.header .navbar-nav .nav-link:hover {
  color: #fce15a;
}
.navbar-logo img {
  height: 55px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .call_us{
    position: absolute;
    right: 0;
    top: 0;
    right: 90px;
  }
}
@media only screen and (min-width: 991px) {
  .call_us{
    position: absolute;
    right: 0;
    top: 0;
    right: 200px;
  }
}
.call_us a{
  font-size: 15px;
    font-weight: 500;
    padding:21px 15px;
    text-transform: capitalize;
    color: #ffffff;
}
