.property_list_div{
    padding: 70px 0 30px 0;
    background-color: #f5f5f5;
}
.property_list{
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.property_list li{
    width: 50%;
    padding: 0px 15px;
}
.property_list_div .card{
    padding: 25px;
}
.property_images{
    width: 216px;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-clip: padding-box; 
}
.property_images a{
    display: block;
    height: 100%;
}
.property_images a img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.property_list_li .card{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    background-color: #fff;
    border:none;
    cursor: pointer;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 24%), 0 1px 4px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 24%), 0 1px 4px 0 rgb(0 0 0 / 12%);
    -webkit-transition: box-shadow .3s ease;
    -moz-transition: box-shadow .3s ease;
    -o-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    flex-direction: initial;
    position: relative;
    padding: 0;
}
.property_info{
    width: calc(100% - 216px);
    padding:15px 20px;
    position: relative;
    display: flex;
    align-items: center;
}
.images_count{
    width: auto;
    padding: 4px 6px;
    display: flex;
    text-align: center;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 4px;
    background-clip: padding-box;
    background-color: rgba(0,0,0,0.75);
    font-size: 12px;
}

.single_property{
    display: flex;
}
.property_cost h4{
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
}
.single_property{
    width:100%;
}
.property_info h6{
    color: #303030;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.property_info h6 a{
    color: #303030;
}
.property_info p{
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 78%;
    vertical-align: bottom;
    margin-right: 0;
}
.property_description{
    position: relative;
}
.property_description a{
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    position: absolute;
    right: 0;
    top: 0;
}
.property_address{
    color: #666;
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 5px;
}
.info_inner{
    width: 100%;
}
.property_btns{
    display: flex;
    justify-content: space-between;
}

.property_row{
    margin: 0px -15px;
}

.prop_desc p{
    font-size: 14px;

}